import * as React from "react";
import { Link } from "gatsby";
import { FocusAreaIcon } from "../components/svg";

const AreasSelection = () => (
  <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 sm:gap-24 lg:gap-8 mt-20 sm:mt-36">
    {areasData.map(function (data) {
      return (
        <Link to={data.url} key={data.id} className="bg-white p-6 sm:p-8 xl:px-12 xl:pt-10 xl:pb-12">
          <div className="-mt-14 sm:-mt-24 mb-6 sm:mb-12">
            <FocusAreaIcon icon={data.icon} className="w-auto h-32 sm:h-48 mx-auto text-blue-800 fill-current" />
          </div>
          <h3 className="text-2xl sm:text-4xl text-center text-blue-800 leading-tight sm:leading-tight">{data.title}</h3>
          <p className="mt-4 sm:mt-8">{data.content}</p>
        </Link>
      );
    })}
  </div>
);

const areasData = [
  {
    id: 0,
    title: "Government",
    content:
      "Nearly all countries and Governments aspire to use digital technologies in providing services to its citizens and residents. Levecode focuses on basic foundational data systems that are of vital importance for effective digital governance and integrity of the whole ecosystem.",
    icon: "government",
    url: "/focus-areas/government",
  },
  {
    id: 1,
    title: "Healthcare",
    content:
      "Information about our health is one of the most important things to us. The same goes for getting proper hospital treatment or healthcare advice. Accurate information regarding person’s blood type, chronic health issues, and medication history literally become a matter of life and death.",
    icon: "healthcare",
    url: "/focus-areas/healthcare",
  },
  {
    id: 2,
    title: "Fintech",
    content:
      "Financial Management and Services are the areas that have reaped tremendous benefits from technological advancements. Besides making it faster and more convenient to transfer funds from one entity to another, digital currencies have opened a whole new array of opportunities for transferring of value, ownership, and accumulating wealth.",
    icon: "fintech",
    url: "/focus-areas/fintech",
  },
];

export default AreasSelection;
