import * as React from "react";
import { StripesIcon } from "./svg";

const StripesDecoration = ({ className }) => (
  <div className="container max-w-screen-3xl relative">
    <StripesIcon className={(className ? className : "text-yellow-800") + " block absolute -top-6 sm:-top-12 right-6 sm:right-14 fill-current h-12 sm:h-24"} />
  </div>
);

export default StripesDecoration;
